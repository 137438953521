import styled from 'styled-components';
import { motion } from 'framer-motion';

export const LandingWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    position: relative;
`;

export const LandingContent = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    height: 20%;
    min-width: 300px;
`;

export const LandingCaptionWrapper = styled(motion.div)`
    z-index: 1;

`;

export const LandingCaption = styled(motion.p)`
    color: #F5F1EC;
    z-index: 1;
    margin-bottom: 1rem;
    font-family: 'Red Hat Display', 'Open Sans';
    white-space: nowrap;
    
`;

export const LandingFlags = styled(motion.div)`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
`;

export const LandingFlag = styled(motion.div)`
    background: url(${({ src }) => src}) content-box;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    border: 1px solid #F5F1EC;
    padding: 3px;
    position: relative;
    margin: 0.5rem;
    z-index: 1;
    &::after {
        content: '${({ tooltip }) => tooltip}';
        position: absolute;
        color: #F5F1EC;
        bottom: -80%;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        transition: opacity 0.5s;
        opacity: 0;
        user-select: none;
        font-family: 'Red Hat Display', 'Open Sans'

    }  
    &:hover::after {
        opacity: 1;
    }
`;
