import React, { useEffect } from "react"
import Landing from "src/components/_shared/landing/landing"
import { navigate } from "gatsby"
import { locales } from "../../globals"
import SEO from 'src/components/_shared/seo';

const Index = () => {
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language")
    if (!locales.includes(storedLanguage))
      localStorage.setItem("language", "PL")
    navigate(`/${localStorage.getItem("language").toLowerCase()}/`)
  }, [])

  return (
    <SEO
      title="Bitwa Warszawska 1920"
      lang="pl"
      description="Jak Polacy uratowali Europę przed komunizmem. Multimedialny przewodnik po 18. decydującej bitwie świata."
    />
  )
}
export default Index
